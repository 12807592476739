import localeRussia from '@angular/common/locales/ru';

export const configuration = {
    company: "Maxibooking Ltd",
    app_title: "ServiceSales",
    agreement_date: "2023-07-10",
    start_year_project: '2023',
    default_lang: 'ru',
    lang_list: ['ru'],
    default_calendar_interval: 15,
    calendar_interval: 15,
    minutes_duration: [0, 15, 30, 45],
    social: {
        facebook: {
            icon: "assets/images/social/Facebook-Original.svg",
            icon_gray: "assets/images/social/Facebook-Original.svg",
            visible: false,
            url: "https://www.facebook.com/servicesalesappcom"
        },
        instagram: {
            icon: "assets/images/social/Instagram-Original.svg",
            icon_gray: "assets/images/social/Instagram-Original.svg",
            visible: false,
            url: "https://www.instagram.com/servicesalesappcom_ie/"
        },
        vk: {
            icon: "assets/images/social/Vkontakte-Original.svg",
            icon_gray: "assets/images/social/vk-gray.svg",
            visible: true,
            url: "https://vk.com/vkservicesales"
        },
        telegram: {
            icon: "assets/images/social/tg.png",
            icon_gray: "assets/images/social/telegram-gray.svg",
            visible: true,
            url: ""
        },
        linkedin: {
            icon: "assets/images/social/LinkedIn-Original.svg",
            icon_gray: "assets/images/social/LinkedIn-Original.svg",
            visible: false,
            url: ""
        },
        twitter: {
            icon: "assets/images/social/Twitter-Original.svg",
            icon_gray: "assets/images/social/Twitter-Original.svg",
            visible: false,
            url: ""
        },
        youtube: {
            icon: "assets/images/social/YouTube-Original.svg",
            icon_gray: "assets/images/social/YouTube-Original.svg",
            visible: true,
            url: ""
        },
        tiktok: {
            icon: "assets/images/social/tiktok-svgrepo-com.svg",
            visible: false,
            url: "https://www.tiktok.com/@servicesalesapp.com"
        }
    },
    hasPaymentSystem: true,
    analytics: [],
    currency_list: ['CAD', 'EUR', 'RUB', 'USD'],
    default_currency: 'RUB',
    listOfSkippedCountryIDs: [],
    locales: [
        { locale: localeRussia, id: 'ru' },
    ],
    servicePublish: {
        dayCount: 8,
        hourCount: 24
    },
    contacts: {
        telegramPrefix: "https://t.me/",
        whatsappPrefix: "https://wa.me/",
        facebookPrefix: "https://www.facebook.com/",
        instagramPrefix: "https://www.instagram.com/",
        vkPrefix: "https://vk.com/",
        mailPrefix: "mailto:",
        phonePrefix: "tel:",
    },
    professional: {
        name: "professional-name"
    },
    price: {
        symbol: {
            start: '',
            end: 'symbol-narrow'
        }
    },
    hostname: location.origin,
    backend: {
        url: 'https://app.maxibooking.ru:8000',
    },
    flagMenu: false

}
